import React from 'react'
import { Menu, Icon } from 'antd';
import { NavLink, withRouter } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import commonActions from "Actions/common/commonActions.js";
import { menuList } from 'Utils/config'

import './index.less'

const SubMenu = Menu.SubMenu;

class NavLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuTreeNode: []
    };
    this.renderMenu = this.renderMenu.bind(this)
    this.onClickMenuItem = this.onClickMenuItem.bind(this)
  }

  componentDidMount() {
    const { commonActions, collapsed, openKeys, history } = this.props;
    const menuTreeNode = this.renderMenu(menuList);
    this.rootSubmenuKeys = [];
    menuList.forEach(val => {
      if (val.children) {
        this.rootSubmenuKeys.push(val.key)
      }
    });
    this.setState({
      menuTreeNode
    });
    commonActions.nvaLeftStatus(collapsed, openKeys, history.location.pathname)
  }
  onClickMenuItem(MenuItem) {
    const { commonActions } = this.props;
    let targetKey = MenuItem.key;
    let menuItemData = {
      title: MenuItem.item.props.title,
      key: MenuItem.key,
    };
    commonActions.setMenuItem(menuItemData);
    commonActions.setTargetMenuItem(targetKey)
  }
  // 菜单渲染
  renderMenu(data) {
    return data.map((item) => {
      if (item.children) {
        return (
          <SubMenu
            title={
              <span>{item.hasOwnProperty('icon') ? <Icon type={item.icon} /> : null}
                <span>{item.title}</span>
              </span>}
            key={item.key}
          >
            {this.renderMenu(item.children)}
          </SubMenu>
        )
      }
      return <Menu.Item title={item.title} key={item.key}>
        <NavLink
          to={item.key}
          replace
        >
          {item.hasOwnProperty('icon') ? <Icon type={item.icon} /> : null}
          <span>{item.title}</span>
        </NavLink>
      </Menu.Item>
    })
  }
  // 不能同时展开2个
  onOpenChange = (openKey) => {
    const { commonActions, collapsed, openKeys } = this.props;
    const latestOpenKey = openKey.find(key => openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      commonActions.nvaLeftStatus(collapsed, openKey)
    } else {
      commonActions.nvaLeftStatus(collapsed, latestOpenKey ? [latestOpenKey] : [])
    }
  };
  render() {

    const { collapsed, openKeys, targetKey } = this.props;
    const { menuTreeNode } = this.state;
    return (
      <div>
        <NavLink
          to="/index"
          replace
        >
          <div className="logo">
            <img src={require("../../static/images/logo.png")} alt="" />
            <h1 className="logoInfo" style={collapsed ? { display: "none" } : null}>天软考试系统</h1>
          </div>
        </NavLink>

        <Menu
          openKeys={openKeys}
          onOpenChange={this.onOpenChange}
          selectedKeys={[targetKey]}
          theme="dark"
          mode="inline"
          className="menuLeft"
          inlineCollapsed={collapsed}
          onClick={this.onClickMenuItem}
        >
          {menuTreeNode}
        </Menu>
      </div>
    );
  }
}



const mapStateToProps = state => {
  return {
    collapsed: state.common.collapsed,
    openKeys: state.common.openKeys,
    targetKey: state.common.targetKey,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NavLeft));
