import { INDEX } from 'Constants/ActionTypes'

const actions = {
    // 基本信息
    getUserInfo: function () {
        return {
            type: INDEX.GET_USER_INFO,
            payload: {
                request: {
                    method: "get",
                    url: "/open/user/my",
                }
            }
        }
    },
};

export default actions