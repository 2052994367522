import React,{Component} from 'react'
import { withRouter } from 'react-router-dom'
import './error.less'

class Error404 extends Component {
  render() {
    return (
      <div className="error">天软教育云平台 - 考试系统错误页</div>
    )
  }
}
export default withRouter(Error404)
