import React from 'react'
import {
  Row,
  Col,
  Icon,
  Button,
  Menu,
  Dropdown,
  message,
  Form,
  Input,
} from "antd"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import commonActions from "Actions/common/commonActions.js";
import loginActions from "Actions/login/loginActions.js";
import WrapModal from 'Components/Modal/wrapModal'
import './index.less'

const FormItem = Form.Item;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "用户名错误",
      modal: {
        title: null
      },
      isEyeOld: false,
      isEyeNew: false,
      isEyeNewConfirm: false,
      WrapModalLoading: false,
      visible: false,
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this)
    this.logoutConfirm = this.logoutConfirm.bind(this)
    this.modifyPw = this.modifyPw.bind(this)
    this.personalCenter = this.personalCenter.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleOk = this.handleOk.bind(this)
  }

  componentDidMount() {
    let userInfo = localStorage.getItem('user_info');
    if (userInfo) {
      this.setState({
        username: JSON.parse(userInfo).user_name
      })
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => { };
  }

  toggleCollapsed() {
    // 菜单栏状态
    const { commonActions, collapsed } = this.props;
    // 每次咱开收缩都清空一下默认展开项
    let openKeys = [];
    commonActions.nvaLeftStatus(!collapsed, openKeys)
  }

  personalCenter() {
    // 个人中心
    message.warning("待开放")
  }

  modifyPw() {
    // 修改密码
    const { modal } = this.state;
    modal.title = "修改密码";
    modal.width = 540;
    this.setState({
      modal,
      visible: true,
    })
  }

  logoutConfirm() {
    // 退出登录
    const { history, loginActions } = this.props;
    loginActions.loginOut()
      .then(res => {
        const { code } = res.payload;
        if (code === 200) {
          localStorage.clear();
          message.success("退出成功");
          history.push('/login');
        } else {
          message.error(res.payload.message)
        }
      }).catch(err => {
        message.error("网络错误，请稍后再试！")
      });

  }
  handleOk() {
    const { form, commonActions } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (values.new_password !== values.new_password_confirm) {
        message.warning("两次输入密码不一致！");
        form.resetFields(["new_password", "new_password_confirm"]);
        return;
      }
      this.setState({
        WrapModalLoading: true
      });
      const data = {
        old_password: values.old_password,
        new_password: values.new_password,
      };
      commonActions.modifyMyPw(data)
        .then(res => {
          if (res.payload.code === 200) {
            message.success("修改成功");
            this.setState({ visible: false });
          } else {
            message.error(res.payload.message);
          }
          this.setState({
            WrapModalLoading: false,
          })
        }).catch(err => {
          message.error("网络错误，请稍后再试！");
          this.setState({ WrapModalLoading: false });
        })
    })
  }

  handleCancel() {
    this.setState({
      visible: false,
      WrapModalLoading: false,
    })
  }

  render() {
    const { collapsed, form } = this.props;
    const {
      modal,
      visible,
      username,
      WrapModalLoading,
      isEyeOld,
      isEyeNew,
      isEyeNewConfirm,
    } = this.state;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
    };
    let WrapModalContent = null;
    if (visible) {
      WrapModalContent = (
        <Form>
          <FormItem
            label="旧密码"
            {...formItemLayout}
          >
            {getFieldDecorator('old_password', {
              rules: [{ required: true, message: '新密码不能少于6位!', min: 6, }],
              validateTrigger: "onBlur",
            })(
              <Input
                allowClear
                type={isEyeOld ? null : "password"}
                placeholder="请输入旧密码"
                addonAfter={isEyeOld ?
                  <Icon onClick={() => this.setState({ isEyeOld: false })} style={{ fontSize: 18 }} type="eye-invisible" />
                  : <Icon onClick={() => this.setState({ isEyeOld: true })} style={{ fontSize: 18 }} theme="twoTone" twoToneColor="#1890FF" type="eye" />
                }
              />
            )}
          </FormItem>
          <FormItem
            label="新密码"
            {...formItemLayout}
          >
            {getFieldDecorator('new_password', {
              rules: [{ required: true, message: '新密码不能少于6位!', min: 6, }],
              validateTrigger: "onBlur",
            })(
              <Input
                allowClear
                type={isEyeNew ? null : "password"}
                placeholder="请输入新密码"
                addonAfter={isEyeNew ?
                  <Icon onClick={() => this.setState({ isEyeNew: false })} style={{ fontSize: 18 }} type="eye-invisible" />
                  : <Icon onClick={() => this.setState({ isEyeNew: true })} style={{ fontSize: 18 }} theme="twoTone" twoToneColor="#1890FF" type="eye" />
                }
              />
            )}
          </FormItem>
          <FormItem
            label="确认新密码"
            {...formItemLayout}
          >
            {getFieldDecorator('new_password_confirm', {
              rules: [{ required: true, message: '新密码不能少于6位!', min: 6, }],
              validateTrigger: "onBlur",
            })(
              <Input
                allowClear
                type={isEyeNewConfirm ? null : "password"}
                placeholder="请再次输入新密码"
                addonAfter={isEyeNewConfirm ?
                  <Icon onClick={() => this.setState({ isEyeNewConfirm: false })} style={{ fontSize: 18 }} type="eye-invisible" />
                  : <Icon onClick={() => this.setState({ isEyeNewConfirm: true })} style={{ fontSize: 18 }} theme="twoTone" twoToneColor="#1890FF" type="eye" />
                }
              />
            )}
          </FormItem>
        </Form>
      )
    }
    const dropdownMenu = (
      <Menu>
        <Menu.Item key="user" onClick={this.personalCenter}>
          <Icon type="user" />
          <span className="MenuItemIcon">个人中心</span>
        </Menu.Item>
        <Menu.Item key="modifyPw" onClick={this.modifyPw}>
          <i className={`trkj-iconfont tableActionIcon trkj-xiugaimima01`} />
          <span className="MenuItemI">修改密码</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={this.logoutConfirm}>
          <Icon type="logout" />
          <span className="MenuItemIcon">退出登录</span>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className={collapsed ? "header" : "header header-action"} >
        <Row className="header-top">
          <Col span={2} className="navStatus" >
            <Button
              onClick={this.toggleCollapsed}
            >
              <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
            </Button>
          </Col>
          {
            collapsed ?
              <Col span={6} className="logo">
                <img src="../../static/images/logo.png" alt="" />
                <span>天软教育云平台 - 考试系统</span>
              </Col> : null
          }
          <Col className="weather" span={collapsed ? 16 : 22}>
            <span className="userName">欢迎，{username}</span>
            <Dropdown
              overlay={dropdownMenu}
              overlayClassName="settingDropDown"
              trigger={['click']}
            >
              <Icon className="setting" type="setting" />
            </Dropdown>
          </Col>
        </Row>
        <WrapModal
          wrapClassName="applicationModal"
          modalConfig={modal}
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          loading={WrapModalLoading}
          content={WrapModalContent}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    collapsed: state.common.collapsed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(withRouter(Header)));