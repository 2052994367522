import { MY_EXAM } from 'Constants/ActionTypes'

const actions = {
    // 在线考试
    getTableList: function (data) {
        return {
            type: MY_EXAM.GET_MY_EXAM_LIST,
            payload: {
                request: {
                    method: "get",
                    url: `/open/exam/my_exam`,
                    params:data
                }
            }
        }
    },
    // 编辑考试初始化数据
    getActivityDetails: function (id) {
        return {
            type: MY_EXAM.GET_MY_EXAM_DETAILS,
            payload: {
                request: {
                    method: "get",
                    url: `/open/exam/get/id/${id}`,
                }
            }
        }
    },
    // 考题列表
    getQuestionsList: function (id) {
        return {
            type: MY_EXAM.GET_QUESTIONS_LIST,
            payload: {
                request: {
                    method: "get",
                    url: `/open/exam/get_paper/exam_id/${id}`,
                }
            }
        }
    },
    // 考试结果列表
    getAnswerList: function (data) {
        return {
            type: MY_EXAM.GET_ANSWER_LIST,
            payload: {
                request: {
                    method: "get",
                    url: `/open/exam/get_paper_user_answer`,
                    params:data
                }
            }
        }
    },
    // 考试提交答案
    examSubmitAnswer: function (data) {
        return {
            type: MY_EXAM.EXAM_SUBMIT_ANSWER,
            payload: {
                request: {
                    method: "post",
                    url: `/open/exam/exam_submit_answer`,
                    data,
                }
            }
        }
    },
    // 考试提交总答案
    examSubmit: function (data) {
        return {
            type: MY_EXAM.EXAM_SUBMIT,
            payload: {
                request: {
                    method: "post",
                    url: `/open/exam/exam_submit_all_answer`,
                    data,
                }
            }
        }
    },
    // 考试开始时间获取
    getStudentUseAnswerTime: function (data) {
        return {
            type: MY_EXAM.GET_STUDENT_USE_ANSWER_TIME,
            payload: {
                request: {
                    method: "post",
                    url: `/open/exam/first_join_exam`,
                    data,
                }
            }
        }
    },
};

export default actions