import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import Store from './store'
import Routes from './routes'
import * as serviceWorker from "./serviceWorker";
render(
    <Provider store={Store}>
      {Routes}
    </Provider>,
    document.getElementById('root')
);
serviceWorker.unregister();

