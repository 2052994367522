import { COMMON } from 'Constants/ActionTypes';
import _ from 'lodash'
const initialState = {
  collapsed: false,
  openKeys:[],
  menuItemData:[{
    title:"首页",
    key:"/"
  }],
  targetKey:null,
};
// 公共配置
const nvaLeftStatus = (state = initialState, action) => {
  switch (action.type) {
    // 菜单栏状态
    case COMMON.NAV_LEFT_STATUS:
      return {
        ...state,
        collapsed: action.bol,
        openKeys: action.openKeys,
        targetKey: action.targetKey,
      };
    // 菜单和topTab联动
    case COMMON.SET_NAV_MENU_ITEM:
      return {
        ...state,
        // 数组中对象去重，title和key完全相等才去重
        menuItemData: _.uniqWith([...state.menuItemData,action.menuItemData], _.isEqual),

      };
    // 菜单和topTab联动（删除）
    case COMMON.DELETE_NAV_MENU_ITEM:
      if(action.targetMenuItem){
        return {
          ...state,
          // 删除菜单中某一项，完全匹配才删除
          menuItemData:_.differenceWith(state.menuItemData, [action.targetMenuItem], _.isEqual)
        };
      }else{
        return {
          ...state,
          menuItemData:[{
            title:"首页",
            key:"/index"
          }]
        };
      }
    // 菜单和topTab选中值
    case COMMON.SET_NAV_MENU_ITEM_TARGET:
      return {
        ...state,
        targetKey:action.targetKey,
      };
    default:
      return state;
  }
};

export default nvaLeftStatus;
