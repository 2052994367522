import React from 'react'
import { Row,Col } from 'antd';
import {bindActionCreators} from "redux";
import { connect } from 'react-redux'
import commonActions from "Actions/common/commonActions.js";
import Header from 'Components/Header'
// import Footer from 'Components/Footer'
import NavLeft from 'Components/NavLeft'
import 'Static/common.less'
import 'Static/css/icon.less'
import 'Static/icon/iconfont.css'

class Admin extends React.Component{
    componentDidMount(){
      // 获取全局配置

    }

    render(){
      const { collapsed,children } = this.props;
      return (
            <Row className="container">
                <Col span={4} className={collapsed?"nav-left nav-left-shrink":"nav-left"}>
                    <NavLeft/>
                </Col>
                <Col  className={collapsed?"main main-expand":"main"}>
                    <Header/>
                    <Row className="content">
                        {children}
                    </Row>
                    {/*<Footer/>*/}
                </Col>
            </Row>
        );
    }
}
const mapStateToProps = state => {
  return {
    collapsed: state.common.collapsed,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);