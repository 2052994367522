/**
 * 首页
 */

import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  message,
  Button,
  List,
  Radio,
  Checkbox,
  Statistic
} from "antd";
import examActions from "Actions/myExam/examActions.js";
import BaseContentMainHeader from 'Components/Layout/baseContentMainHeader'
const { Countdown } = Statistic;
class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      buttonLoading: false,
      singleList: [], // 单选
      multipleList: [], // 多选
      judgeList: [], // 判断
      examData: {},
      countdownTime: null,
      singleCountScore: 0,
      multipleCountScore: 0,
      judgeCountScore: 0,

    };
    this.submitData = {};
    this.optionsLabel = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  }
  componentDidMount() {
    this.routeParamsCheck();
    this.getQuestionsList();
  }
  // 获取考试试题列表
  getQuestionsList = () => {
    const { examActions, match, history } = this.props;
    this.setState({ loading: true, });
    examActions.getQuestionsList(match.params.id)
      .then(res => {
        const { code, data } = res.payload;
        if (code === 200) {
          if (+data.status === 1 || +data.status === 4 || +data.status === 7) {
            history.push(`/myExam`)
          } else {
            const { paper_info } = data;
            let singleScore = 0;
            let multipleScore = 0;
            let judgeScore = 0;
            paper_info.single.forEach(val => {
              singleScore = singleScore + Number(val.score)
            });
            paper_info.multiple.forEach(val => {
              multipleScore = multipleScore + Number(val.score)
            });
            paper_info.judge.forEach(val => {
              judgeScore = judgeScore + Number(val.score)
            });
            this.setState({
              singleList: paper_info["single"],
              multipleList: paper_info["multiple"],
              judgeList: paper_info["judge"],
              loading: false,
              examData: data,
              singleCountScore: singleScore,
              multipleCountScore: multipleScore,
              judgeCountScore: judgeScore,
            }, () => {
              this.getStudentUseAnswerTime(data.id);
            });
          }
        } else {
          message.error(res.payload.message)
        }
      }).catch(err => {
        message.error("网络错误，请稍后再试！")
      });
  };
  // 获取考生已经答题多少时间

  // 回来先写请求，然后获取到时间之后和使用limited的时候做减法运算
  getStudentUseAnswerTime = (id) => {
    const { examActions } = this.props;
    const { examData } = this.state;
    examActions.getStudentUseAnswerTime({
      exam_id: id
    })
      .then(res => {
        const { code, data } = res.payload;
        if (code === 200) {
          let countdownTime = null;
          if (+examData.status === 2) {
            // 不限考试时间（根据end_time倒计时）
            countdownTime = new Date(examData.end_time).getTime();
          } else if (+examData.status === 3) {
            // 限制考试时间（end_time,limited谁小就用谁倒计时）还要减去他首次进来的时间
            if (new Date(examData.end_time).getTime() > examData.limited * 60 * 1000 + Date.now()) {
              countdownTime = examData.limited * 60 * 1000 + data.join_time * 1000;
            } else {
              countdownTime = (new Date(examData.end_time).getTime() - data.join_time * 1000) + Date.now();
            }
          } else if (+examData.status === 5) {
            // 不限考试日期不限考试时间（没有倒计时）
          } else if (+examData.status === 6) {
            // 不限考试日期限考试时间 （limited倒计时）还要减去首次进来的时间
            countdownTime = examData.limited * 60 * 1000 + data.join_time * 1000;
          }
          this.setState({
            countdownTime
          })
        } else {
          message.error(res.payload.message)
        }
      }).catch(err => {
        message.error("网络错误，请稍后再试！")
      });
  };
  // 验证路由参数
  routeParamsCheck = () => {
    const { match } = this.props;
    // 验证参数是否正确
    if (!match.params.id) {
      this.routeParamsJump()
    }
  };
  // 返回上一层
  routeParamsJump = () => {
    const { history } = this.props;
    history.push("/myExam")
  };
  // 提交
  submit = () => {
    const { examActions, history } = this.props;
    const { examData } = this.state;
    this.setState({ loading: true, buttonLoading: true, });
    let data = {
      exam_id: examData.id,
      exam_paper_id: examData.exam_paper_id,
      all_answer: this.submitData,
    };
    examActions.examSubmit(data)
      .then(res => {
        const { code } = res.payload;
        if (code === 200) {
          message.success("交卷成功");
          history.push(`/myExam/result`)
        } else {
          message.warning(res.payload.message);
        }
        this.setState({ loading: false, buttonLoading: false, })
      }).catch(err => {
        this.setState({ loading: false, buttonLoading: false, })
        message.error("网络错误，请稍后再试！")
      })
  };
  onChangeSubmit = (id, type, e) => {
    const { examActions } = this.props;
    const { examData } = this.state;
    let value = null;
    if (+type === 1 || +type === 3) {
      value = e.target.value;
    } else if (+type === 2) {
      value = e;
    }
    this.submitData[id] = value;
    let data = {
      exam_id: examData.id,
      exam_paper_id: examData.exam_paper_id,
      exam_paper_question_id: id,
      answer: value,
    };
    examActions.examSubmitAnswer(data)
  };
  render() {
    const {
      loading,
      buttonLoading,
      singleList,
      multipleList,
      judgeList,
      examData,
      singleCountScore,
      multipleCountScore,
      judgeCountScore,
      countdownTime,
    } = this.state;
    console.log(singleList);
    console.log(multipleList);
    console.log(judgeList);
    return (
      <>
        <BaseContentMainHeader
          title={`在线考试 - ${examData.name}(${examData.course_name})`}
        >
          <section className="base-content-main-header-data">
            <Button
              type="primary"
              loading={buttonLoading}
              className="exam-activity-button"
              onClick={this.submit}
            >
              提交试卷
            </Button>
            {+examData.status === 5 ?
              null :
              <Countdown
                style={{ height: 50, lineHeight: "50px", marginRight: 20, float: "left" }}
                valueStyle={{ fontSize: 18, color: "red" }}
                prefix={<span style={{ color: "#333" }}>剩余时间：</span>}
                value={countdownTime}
                onFinish={this.submit}
              />
            }

          </section>
        </BaseContentMainHeader>
        <section className="activity-exam-list">
          {Array.isArray(singleList) && singleList.length > 0 ?
            <List
              header={<div className="list-header">一、单选题（共{singleList.length}小题；满分{singleCountScore}分）</div>}
              loading={loading}
              className="exam-list"
              itemLayout="horizontal"
              dataSource={singleList}
              renderItem={(item, index) => {
                return <List.Item>
                  <List.Item.Meta
                    title={<p className="list-title">
                      <label className="list-title-label">[{index + 1}]</label>
                      <span className="list-title-name" dangerouslySetInnerHTML={{ __html: item.name }} />
                      <span className="list-title-score">{item.score} 分</span>
                    </p>}
                    description={
                      <Radio.Group onChange={this.onChangeSubmit.bind(this, item.id, item.type)}>
                        {item.options.map((val, indexs) => {
                          return (
                            <section key={val.id} className="list-info">
                              <label className="list-label">{this.optionsLabel[indexs]}</label>
                              <Radio className="list-radio" value={val.id}>
                                <span className="list-radio-value" dangerouslySetInnerHTML={{ __html: val.name }} />
                              </Radio>
                            </section>
                          )
                        })}
                      </Radio.Group>
                    }
                  />
                </List.Item>
              }}
            /> : null}
          {Array.isArray(multipleList) && multipleList.length > 0 ?
            <List
              header={< div className="list-header">二、多选题（共{multipleList.length}小题；满分{multipleCountScore}分）</div>}
              loading={loading}
              className="exam-list"
              itemLayout="horizontal"
              dataSource={multipleList}
              renderItem={(item, index) => {
                return <List.Item>
                  <List.Item.Meta
                    title={<p className="list-title">
                      <label className="list-title-label">[{index + 1}]</label>
                      <span className="list-title-name" dangerouslySetInnerHTML={{ __html: item.name }} />
                      <span className="list-title-score">{item.score} 分</span>
                    </p>}
                    description={
                      <Checkbox.Group onChange={this.onChangeSubmit.bind(this, item.id, item.type)}>
                        {item.options.map((val, indexs) => {
                          return (
                            <section key={val.id} className="list-info">
                              <label className="list-label">{this.optionsLabel[indexs]}</label>
                              <Checkbox className="list-radio" value={val.id}>
                                <span className="list-radio-value" dangerouslySetInnerHTML={{ __html: val.name }} />
                              </Checkbox>
                            </section>
                          )
                        })}
                      </Checkbox.Group>
                    }
                  />
                </List.Item>
              }}
            /> : null}
          {Array.isArray(judgeList) && judgeList.length > 0 ?
            <List
              header={< div className="list-header">三、判断题（共{judgeList.length}小题；满分{judgeCountScore}分）</div>}
              loading={loading}
              className="exam-list"
              itemLayout="horizontal"
              dataSource={judgeList}
              renderItem={(item, index) => {
                return <List.Item>
                  <List.Item.Meta
                    title={<p className="list-title">
                      <label className="list-title-label">[{index + 1}]</label>
                      <span className="list-title-name" dangerouslySetInnerHTML={{ __html: item.name }} />
                      <span className="list-title-score">{item.score} 分</span>
                    </p>}
                    description={
                      <Radio.Group onChange={this.onChangeSubmit.bind(this, item.id, item.type)}>
                        <Radio className="list-judge-radio" value={1}>
                          对
                      </Radio>
                        <Radio className="list-judge-radio" value={0}>
                          错
                      </Radio>
                      </Radio.Group>
                    }
                  />
                </List.Item>
              }}
            /> : null}
        </section >
      </>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    examActions: bindActionCreators(examActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Activity);