// 生产环境api地址配置
function envAxiosUrl() {
    let baseURL = '/';
    if (process.env.REACT_ENV_AXIOS_URL === 'dev_production') {
        // baseURL = 'http://39.106.177.34:5201/';
        baseURL = '/';
        return baseURL
    } else if (process.env.REACT_ENV_AXIOS_URL === 'production') {
        baseURL = '/';
        // baseURL = 'http://39.106.177.34:5201/';
        return baseURL
    } else {
        return baseURL
    }
}

// 菜单配置
const menuList = [
    {
        title: '个人信息',
        key: '/index',
        icon: 'user',
    },
    {
        title: '在线考试',
        key: '/myExam',
        icon: 'calculator',
    },
];

//全局Input最大长度
let INPUT_MAX_LENGTH = 50;

export {
    envAxiosUrl,
    menuList,
    INPUT_MAX_LENGTH,
};
