//全局外壳
import React, { Component } from "react";
import {
  Layout,
  LocaleProvider,//国际化
} from "antd";
import { withRouter } from 'react-router-dom'

import zh_CN from "antd/lib/locale-provider/zh_CN";
import 'moment/locale/zh-cn';
import "./app.less"; //全局样式
const { Content } = Layout;


class App extends Component {

  render() {
    const { children } = this.props;
    return (
      <LocaleProvider locale={zh_CN}>
        <div className="container">
          <Layout style={{ height: '100vh' }}>
              <Content className="main-content">
                {children}
              </Content>
          </Layout>
        </div>
      </LocaleProvider>
    );
  }
}

export default withRouter(App);
