
/*
* actions常量
* */

// 公共
export const COMMON = {
  NAV_LEFT_STATUS: 'NAV_LEFT_STATUS', //菜单栏状态
  SET_NAV_MENU_ITEM: 'SET_NAV_MENU_ITEM', //打开的菜单和topTab统一数据
  SET_NAV_MENU_ITEM_TARGET: 'SET_NAV_MENU_ITEM_TARGET', //打开的菜单和topTab统一数据
  DELETE_NAV_MENU_ITEM: 'DELETE_NAV_MENU_ITEM',
};
// 登录
export const LOGIN = {
  LOGIN: 'LOGIN', // 登录
  LOGIN_OUT: 'LOGIN_OUT', // 推出登录
  LOGIN_CHECK: 'LOGIN_CHECK', // 登录验证
};
// 我的信息
export const INDEX = {
  GET_USER_INFO: 'GET_USER_INFO',
};
// 我的考试
export const MY_EXAM = {
  GET_MY_EXAM_LIST: 'GET_MY_EXAM_LIST',
  GET_MY_EXAM_DETAILS: 'GET_MY_EXAM_DETAILS',
  GET_QUESTIONS_LIST: 'GET_QUESTIONS_LIST',
  GET_ANSWER_LIST: 'GET_ANSWER_LIST',
  EXAM_SUBMIT_ANSWER: 'EXAM_SUBMIT_ANSWER',
  EXAM_SUBMIT: 'EXAM_SUBMIT',
  GET_STUDENT_USE_ANSWER_TIME: 'GET_STUDENT_USE_ANSWER_TIME',
};