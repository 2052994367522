/**
 * 详情页
 */
import React,{Component} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    message,
    Button,
    Spin,
    Descriptions,
    Badge,
    Statistic,
} from "antd";
import moment from 'moment';
import examActions from "Actions/myExam/examActions.js";
import BaseContentMainHeader from 'Components/Layout/baseContentMainHeader'
import './index.less'
const { Countdown } = Statistic;
const DescriptionsItem = Descriptions.Item;
class Details extends Component{
    constructor(props){
        super(props);
        this.state = {
            activityData:[],
            loading:false,
            buttonDisabled:true
        }
    }
    componentDidMount() {
        this.routeParamsCheck();
        this.getActivityDetails();
    }
    onFinish = () => {
        this.setState({
            buttonDisabled:false
        })
    };
    // 获取详情
    getActivityDetails = () => {
        const { examActions,match,history } = this.props;
        this.setState({loading:true});
        let id = match.params.id;
        examActions.getActivityDetails(id)
            .then(res => {
                const { data,code } = res.payload;
                if(code === 200){
                    if(+data.status !== 1){
                        history.push("/myExam");
                        message.warning("考试状态错误！")
                    }else{
                        this.setState({
                            activityData:data,
                            loading: false,
                        });
                    }
                }else{
                    message.error(res.payload.message);
                    this.routeParamsJump()
                }
            }).catch((err) => {
            message.error("网络错误，请稍后再试！");
            this.routeParamsJump()
        });
    };
    // 验证路由参数
    routeParamsCheck = () => {
        const {match} = this.props;
        // 验证参数是否正确
        if(!match.params.id){
            this.routeParamsJump()
        }
    };
    // 返回上一层
    routeParamsJump = () => {
        const { history } = this.props;
        history.push("/myExam")
    };
    startExam = () => {
        const { history,match } = this.props;
        let id = match.params.id;
        history.push(`/myExam/activityExam/${id}`)
    };
    render(){
        const { activityData,loading,buttonDisabled } = this.state;
        return (
            <>
                <BaseContentMainHeader title="在线考试 - 考试详情" />
                <div className="my-exam-activity">
                    <Spin spinning={loading}>
                        <Descriptions column={6} layout="horizontal " bordered>
                            <DescriptionsItem span={6} label="考试名称">
                                <Badge status="processing" text={activityData.name} />
                            </DescriptionsItem>
                            <DescriptionsItem span={3} label="试卷总分">
                                {activityData.count_score?
                                    <>{activityData.count_score}分</> :
                                    null
                                }
                             </DescriptionsItem>
                            <DescriptionsItem span={3} label="考试时长">
                                {moment(activityData.end_time).diff(moment(activityData.start_time), 'minute')} 分钟
                            </DescriptionsItem>
                            <DescriptionsItem span={3} label="开始时间">
                                {activityData.start_time}
                            </DescriptionsItem>
                            <DescriptionsItem span={3} label="结束时间">
                                {activityData.end_time}
                            </DescriptionsItem>
                        </Descriptions>
                        <section className="countDown">
                            <Countdown
                                title="考试倒计时："
                                value={activityData.start_time}
                                format="D 天 H 时 m 分 s 秒"
                                onFinish={this.onFinish}
                            />

                        </section>
                        <section  className="exam-activity-button">
                            <Button
                                onClick={this.routeParamsJump}
                                style={{marginRight:24}}
                            >
                                返回列表
                            </Button>
                            <Button
                                type="primary"
                                disabled={buttonDisabled}
                                onClick={this.startExam}
                            >
                                开始答题
                            </Button>
                        </section>
                    </Spin>
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        examActions: bindActionCreators(examActions, dispatch),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Details);