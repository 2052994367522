import React,{Component} from "react";

import "./index.less";

class BaseContentMainBottomAction extends Component {
  render (){
    const {title,children,styles} = this.props;
    return (
      <div>
        <div className="base-content-main-header" style={styles}>
          <div className="base-content-main-header-inner">
            <div className="antdx-content-header-title">{title}</div>
            <div className="antdx-content-header-btns">{children}</div>
          </div>
        </div>
        <div className="base-content-main-header-background" />
      </div>
    )
  }
}

export default BaseContentMainBottomAction;
