import React,{Component} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
  Descriptions,
  Badge,
  Icon,
  message,
  Spin,
} from 'antd'
import indexActions from "Actions/index/indexActions.js";
import BaseContentMainHeader from 'Components/Layout/baseContentMainHeader'
import './index.less'

const DescriptionsItem = Descriptions.Item;
class Index extends Component{
  constructor(props){
    super(props);
    this.state = {
      data:{},
      loading:false,
    }
  }
  componentDidMount() {
    this.setState({loading:true});
    const { indexActions } = this.props;
    indexActions.getUserInfo()
        .then(res=>{
          const { code,data } = res.payload;
          if(code === 200){
            this.setState({
              data,
              loading:false
            });
          }else {
            message.error(res.payload.message)
          }
        }).catch(err=>{
      console.log(err);
      message.error("网络错误，请稍后再试！")
    })
  }
  componentWillUnmount(){
    this.setState = (state, callback) => {};
  }
  render(){
    const { data,loading } = this.state;
    return (
        <>
          <BaseContentMainHeader title="我的信息" />
          <div className="home-wrap">
            <Spin spinning={loading}>
              <Descriptions column={6} layout="horizontal " bordered>
                <DescriptionsItem span={3} label="姓名">
                  <Badge status="processing" text={data.true_name} />
                </DescriptionsItem>
                <DescriptionsItem span={2} label="班级职务">{data.position}</DescriptionsItem>
                <DescriptionsItem span={1} label="性别">
                  {data.sex === 0? <><Icon type="woman"  style={{color:"red",marginRight:8}} />女</>:null}
                  {data.sex === 1? <><Icon type="man"  style={{color:"#1890ff",marginRight:8}} />男</>:null}
                </DescriptionsItem>
                <DescriptionsItem span={3} label="所在学校">
                  <Badge status="processing" text={data.org_name} />
                </DescriptionsItem>
                <DescriptionsItem span={2} label="所在班级">{data.classes_name}</DescriptionsItem>
                <DescriptionsItem span={1} label="班级人数">{data.classes_count_students} 人</DescriptionsItem>
                <DescriptionsItem span={6} label="身份证">{data.identity_number}</DescriptionsItem>
              </Descriptions>
            </Spin>
          </div>
        </>
    );
  }
}
const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    indexActions: bindActionCreators(indexActions, dispatch),
  };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index);