/*
 * 我的考试
 */
import React,{Component} from 'react'
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
  Table,
  message,
  Icon,
} from 'antd'
import examActions from "Actions/myExam/examActions.js";
import BaseContentMainBottomActions from "Components/Layout/baseContentMainBottomActions";
import BaseContentMainHeader from 'Components/Layout/baseContentMainHeader'


class Index extends Component{
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      dataSource:[],
      pagination: {
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        size: 'small',
        total:0,
      },
    };
    this.columns = [
      {
        title: '考试名称',
        dataIndex: 'name',
        key: 'name',
        render:(text,record)=><div onClick={this.getExamState.bind(this,record.id)} style={{color:"#1890ff",cursor:"pointer"}}><Icon style={{marginRight:6}} type="laptop" />{text}</div>

      },{
        title: '考试类型',
        dataIndex: 'category_name',
        key: 'category_name',
      },{
        title: '课程',
        dataIndex: 'course_name',
        key: 'course_name',
      },{
        title: '试卷',
        dataIndex: 'exam_paper_name',
        key: 'exam_paper_name',
      },{
        title: '总分',
        dataIndex: 'count_score',
        key: 'count_score',
      },{
        title: '及格分数',
        dataIndex: 'pass_score',
        key: 'pass_score',
      },{
        title: '考试状态',
        dataIndex: 'status',
        key: 'status',
        render:text=>{
          switch (text) {
            case 1:
              return "未开始";
            case 2:
              return "进行中(不限考试时间)"; // 倒计时根据end_time
            case 3:
              return "进行中(限制考试时间)"; // end_time,lim靠前的那个
            case 4:
              return "已结束";
            case 5:
              return "不限考试日期不限考试时间"; // 没倒计时
            case 6:
              return "不限考试日期限考试时间"; // lim的那个
            case 7:
              return "已交卷"; // lim的那个
            default:
              return "状态码错误"
          }
        }
      },{
        title: '考试时长',
        dataIndex: 'limited',
        key: 'limited',
        render:text=>text? <span>{text} 分钟</span> :null
      },{
        title: '开始时间',
        dataIndex: 'start_time',
        key: 'start_time',
      },{
        title: '结束时间',
        dataIndex: 'end_time',
        key: 'end_time',
      },{
        title: '添加人',
        dataIndex: 'create_user_name',
        key: 'create_user_name',
      },{
        title: '操作 ',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width:120,
        render:(text, record)=>{
          if(
            (+record.status === 4) ||
            (+record.status === 7 && !(record.start_time && record.end_time))
          ){
            return (<Link to={`/myExam/achievementQuery/${record.id}`}>查询考试成绩</Link> )
          }
        }
      }
    ];
  }
  // 表格的数据源
  componentDidMount() {
    this.getTableList();
  }
  componentWillUnmount(){
    this.setState = (state, callback) => {};
  }
  // 获取列表
  getTableList = () => {
    const { examActions } = this.props;
    const { pagination } = this.state;
    this.setState({
      loading: true,
    });
    examActions.getTableList({
      page: pagination.current,
      page_number: pagination.pageSize,
    }).then(res => {
      if(res.payload.code === 200 ){
        const { data } = res.payload;
        const { page,page_number,count,list} = data;
        pagination.total = Number(count);
        pagination.pageSize = Number(page_number || 10);
        pagination.current = Number(page || 1);
        this.setState({
          pagination,
          dataSource:list,
          loading: false,
        })
      }else{
        message.error(res.payload.message)
      }
    }).catch(err => {
      message.error("网络错误，请稍后再试！")
    });
  };
  getExamState = (id) => {
    const { examActions,history } = this.props;
    examActions.getActivityDetails(id)
      .then(res => {
        const { data,code } = res.payload;
        if(code === 200){
          if(+data.status === 1){
            history.push(`/myExam/details/${id}`)
          }else if(+data.status === 2 || +data.status === 3 || +data.status === 5 || +data.status === 6){
            history.push(`/myExam/activity/${id}`)
          }else if(+data.status === 4 || +data.status === 7){
            history.push(`/myExam/result`)
          }else{
            message.warning("状态错误！")
          }
        }else{
          message.error(res.payload.message);
        }
      }).catch((err) => {
      message.error("网络错误，请稍后再试！");
    });
  };
  // 分页
  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
    },()=>this.getTableList());
  };


  render(){
    const {
      loading,
      dataSource,
      pagination,
    } = this.state;
    return(
      <div
        className="my-exam"
      >
        <BaseContentMainHeader title="在线考试" />
        <Table
          className="table-list"
          loading={loading}
          bordered={true}
          columns={this.columns}
          rowKey={record => record.id}
          onChange={this.handleTableChange}
          dataSource={dataSource?dataSource:[]}
          scroll={{x:1200}}
          size="small"
          pagination={{
            ...pagination,
            pageSizeOptions: ["10", "20", "30", "40"],
            showTotal: (total, range) => (
              <BaseContentMainBottomActions
                showTotalRender={`显示${range[0]}到${
                  range[1]
                  }, 共 ${pagination.total} 条数据`}>
              </BaseContentMainBottomActions>
            )
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    examActions: bindActionCreators(examActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

