import React,{Suspense,lazy} from 'react'
import {
  HashRouter as
    BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import App from 'Containers/app'
import Admin from './admin'


// 基本信息
import Index from 'Containers/index'
/*
* 我的教学 teachingSystem
*   我的考试 myExam
*/

import MyExam from 'Containers/myExam'
import MyExamDetails from 'Containers/myExam/details'
import MyExamActivity from 'Containers/myExam/activity'
import AchievementQuery from 'Containers/myExam/achievementQuery'
import MyExamResult from 'Containers/myExam/result'

// 错误页
import Error404 from 'Containers/errorPages/error404'

import HocPrivateRoute from 'Components/HocPrivateRoute';
const  PrivateRoute =  HocPrivateRoute(Route);
// 登录
// import Login from 'Containers/login'
const Login = lazy(() => import('Containers/login'));
const Routes = (
  <BrowserRouter>
    <Suspense fallback={false}>
      <App>
        <Switch>
          <Route path={['/login/:reurl','/login']} component={Login} />
          <Admin>
            <Switch>
              <PrivateRoute exact  path={['/','/index']}  component={Index} />
              <PrivateRoute exact  path='/myExam'  component={MyExam} />
              <PrivateRoute exact  path='/myExam/details/:id'  component={MyExamDetails} />
              <PrivateRoute exact  path='/myExam/activity/:id'  component={MyExamActivity} />
              <PrivateRoute exact  path='/myExam/achievementQuery/:id'  component={AchievementQuery} />
              <PrivateRoute exact  path='/myExam/result'  component={MyExamResult} />

              <PrivateRoute path='/error/404' component={Error404} />
              <Redirect to="/error/404" />
            </Switch>
          </Admin>
        </Switch>
      </App>
    </Suspense>
  </BrowserRouter>
);
export default Routes
