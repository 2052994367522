/**
 * 首页
 */

import React,{Component} from 'react'
import {
  Result,
  Button,
  Icon,
} from 'antd'
class Index extends Component{
  onClick = () => {
    const { history } = this.props;
    history.push("/myExam")
  };
  render(){
    return (
        <Result
          className="exam-result"
          icon={<Icon type="smile" theme="twoTone" />}
          title="考试已结束或已交卷"
          extra={<Button size="large" style={{width:240,marginLeft:10}} onClick={this.onClick} type="primary">返回列表</Button>}
        />
    );
  }
}
export default Index