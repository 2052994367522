/*
 * @Descripttion: 
 * @Author: tanwei
 * @Date: 2019-07-28 17:11:10
 * @LastEditors: tanwei
 * @LastEditTime: 2020-03-24 11:00:07
 * @FilePath: \trkj_jypt_web\src\utils\axios_middleware_config.js
 */
import Ajax from 'Ajax'
import qs from 'qs'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const middlewareConfig = {
  //请求出错时返回Promise reject
  returnRejectedPromiseOnError: true,
  //拦截器
  interceptors: {
    request: [{
      //处理成功请求
      success: ({
        getState,
        dispatch,
        getSourceAction
      }, config) => {
        NProgress.start();
        // 在每次请求前添加cancelKey,cancel请求
        config.reqId = new Date().getTime();
        config.cancelToken = Ajax.addRequest(config.reqId);

        // 每次请求的时候添加headers头的token验证
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'; //改为form Data
        config.headers["access-token"] = localStorage.getItem('access-token');
        config.data = qs.stringify(config.data); //改为form Data

        // 如果是jsonp，则设置适配器来处理请求
        if (config.jsonp) {
          config.adapter = config => {
            return Ajax.jsonp(config)
          }
        }

        return config
      },
      //处理失败请求
      error: ({
        getState,
        dispatch,
        getSourceAction
      }, error) => {
        return Promise.reject(error);
      }
    }],
    response: [{
      //处理成功返回
      success: ({
        getState,
        dispatch,
        getSourceAction
      }, res) => {
        NProgress.done();
        //请求完成,移除cencel栈
        Ajax.removeRequest(res.config.reqId);
        // const source = getSourceAction(res.config);
        const data = res.data;
        //判断服务端返回状态是否正确
        const isSuccess = data && data.code === 200;

        const newToken = res.headers["access-token"];
        const oldToken = localStorage.getItem('access-token');
        if (res.headers["access-token"] && newToken !== oldToken) {
          localStorage.setItem('access-token', newToken);
        }
        if (isSuccess) {

        } else {
          //如果错误  需要
          if (data.code === 403) {
            localStorage.clear();
            window.location.href = '/#/login'
          }
        }
        return data
      },
      //处理失败返回
      error: ({
        getState,
        dispatch,
        getSourceAction
      }, error) => {
        //取消操作错误不发出
        if (Ajax.axios.isCancel(error)) {
          console.log('Request canceled', error.message)
        } else {
          //Message.error('网络异常')
          return Promise.reject(error)
        }
      }
    }]
  }
};

export default middlewareConfig
