import React from "react";
import classNames from "classnames";
import "./index.less";

const BaseContentMainBottomAction = ({
  children,
  className = "",
  showTotalRender,
  ...otherProps
}) => {
  let Cls = classNames("base-content-main-bottom-actions", className);
  return (
    <div className={Cls}>
      <div className="bottom-actions">{children}</div>
      {showTotalRender}
    </div>
  );
};

export default BaseContentMainBottomAction;
