/*
* 公共actions
* */
import { COMMON } from 'Constants/ActionTypes'

const actions = {
  // 菜单栏状态
  nvaLeftStatus: function(bol,openKeys,targetKey) {
    return {
      type: COMMON.NAV_LEFT_STATUS,
      bol,
      openKeys,
      targetKey
    }
  },

  //根据菜单栏和设置topTab
  setMenuItem: function(menuItemData) {
    return {
      type: COMMON.SET_NAV_MENU_ITEM,
      menuItemData,
    }
  },
  //菜单栏和topTab选中目标
  setTargetMenuItem: function(targetKey) {
    return {
      type: COMMON.SET_NAV_MENU_ITEM_TARGET,
      targetKey,
    }
  },
  //根据菜单栏和删除topTab
  deleteMenuItem: function(targetMenuItem) {
    return {
      type: COMMON.DELETE_NAV_MENU_ITEM,
      targetMenuItem,
    }
  },
};

export default actions
