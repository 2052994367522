/**
 * 首页
 */

import React,{Component} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
  message,
  List,
  Radio,
  Checkbox, Button,
} from "antd";
import examActions from "Actions/myExam/examActions.js";
import BaseContentMainHeader from 'Components/Layout/baseContentMainHeader'
class AchievementQuery extends Component{
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      singleList:[], // 单选
      multipleList: [], // 多选
      judgeList:[], // 判断
      examData:{},
      singleCountScore:0,
      multipleCountScore:0,
      judgeCountScore:0,
    };
    this.optionsLabel = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
  }
  componentDidMount() {
    this.routeParamsCheck();
    this.getAnswerList();
  }

  // 获取考试试题列表
  getAnswerList = () => {
    const { examActions,match,history } = this.props;
    this.setState({loading: true,});
    examActions.getAnswerList({
      exam_id:match.params.id
    })
      .then(res => {
        const { code,data} = res.payload;
        if(code === 200 ){
          const { paper_info } = data;
          let singleScore = 0;
          let multipleScore = 0;
          let judgeScore = 0;
          paper_info.single.forEach(val=>{
            singleScore = singleScore+Number(val.score)
          });
          paper_info.multiple.forEach(val=>{
            multipleScore = multipleScore+Number(val.score)
          });
          paper_info.judge.forEach(val=>{
            judgeScore = judgeScore+Number(val.score)
          });
          this.setState({
            singleList:paper_info["single"],
            multipleList:paper_info["multiple"],
            judgeList:paper_info["judge"],
            loading: false,
            examData:data,
            singleCountScore:singleScore,
            multipleCountScore:multipleScore,
            judgeCountScore:judgeScore,
          });
        }else{
          history.push(`/myExam`);
          message.error(res.payload.message)
        }
      }).catch(err => {
      message.error("网络错误，请稍后再试！")
    });
  };

  // 验证路由参数
  routeParamsCheck = () => {
    const {match} = this.props;
    // 验证参数是否正确
    if(!match.params.id){
      this.routeParamsJump()
    }
  };

  // 返回上一层
  routeParamsJump = () => {
    const { history } = this.props;
    history.push("/myExam")
  };

  render(){
    const {
      loading,
      singleList,
      multipleList,
      judgeList,
      examData,
      singleCountScore,
      multipleCountScore,
      judgeCountScore,
    } = this.state;
    return (
      <>
        <BaseContentMainHeader title={`成绩查询 - ${examData.name}(${examData.course_name})`}>
          <span style={{marginRight:30}}>卷面总分：
            <span style={{marginRight:10,fontSize:24,color:"red"}}>
              {examData&&examData.hasOwnProperty("paper_info")&&examData.paper_info.hasOwnProperty("count_score")?+(examData.paper_info.count_score):null}
            </span>分
          </span>
          <span style={{marginRight:30}}>及格分：
            <span style={{marginRight:10,fontSize:24,color:"red"}}>
              {examData&&examData.hasOwnProperty("paper_info")&&examData.paper_info.hasOwnProperty("pass_score")?+(examData.paper_info.pass_score):null}
            </span>分
          </span>
          <span style={{marginRight:30}}>考试得分：
            <span style={{marginRight:10,fontSize:24,color:"red"}}>
              {examData&&examData.hasOwnProperty("exam_result")&&examData.exam_result.hasOwnProperty("score")?+(examData.exam_result.score):0}
            </span>分
          </span>
          <Button
            type="primary"
            className="exam-activity-button"
            onClick={this.routeParamsJump}
          >
            返回列表
          </Button>
        </BaseContentMainHeader>
        <section className="activity-exam-list">
          <List
            header={<div className="list-header">一、单选题（共{singleList.length}小题；满分{singleCountScore}分）</div>}
            loading={loading}
            className="exam-list"
            itemLayout="horizontal"
            dataSource={singleList}
            renderItem={(item,index) => {
              return <List.Item>
                <List.Item.Meta
                  title={<p className="list-title">
                    <label className="list-title-label">[{index+1}]</label>
                    <span className="list-title-name" dangerouslySetInnerHTML={{__html: item.name}} />
                    <span className="list-title-score list-title-score-1">本题分值：{+item.score} 分</span>
                    <span className="list-title-score">我的得分：
                      <span style={{fontSize:20}}>{+item.my_score}</span> 分
                    </span>
                  </p>}
                  description={
                    <>
                      <Radio.Group disabled defaultValue={item.my_answer}>
                        {item.options.map((val,indexs)=>{
                          return (
                            <section key={val.id} className="list-info">
                              <label className="list-label">{this.optionsLabel[indexs]}</label>
                              <Radio className="list-radio"  value={val.id}>
                                <span className="list-radio-value" dangerouslySetInnerHTML={{__html: val.name}} />
                              </Radio>
                            </section>
                          )
                        })}
                      </Radio.Group>
                      <section className="answer">
                        <p className="exactness">
                          <span className="label">正确答案：</span>
                          <span className="info">{
                            item.options.map((val,index)=>{
                              if(item.answer === val.id){
                                return this.optionsLabel[index]
                              }
                              return false;
                            })
                          }</span>
                        </p>
                        <p className="analysis">
                          <span className="label">答案解析：</span>
                          <span className="info" dangerouslySetInnerHTML={{__html:item.analyze}} />
                        </p>
                      </section>
                    </>
                  }
                />
              </List.Item>
            }}
          />
          <List
            header={<div className="list-header">二、多选题（共{multipleList.length}小题；满分{multipleCountScore}分）</div>}
            loading={loading}
            className="exam-list"
            itemLayout="horizontal"
            dataSource={multipleList}
            renderItem={(item,index) => {
              return <List.Item>
                <List.Item.Meta
                  title={<p className="list-title">
                    <label className="list-title-label">[{index+1}]</label>
                    <span className="list-title-name" dangerouslySetInnerHTML={{__html: item.name}} />
                    <span className="list-title-score list-title-score-1">本题分值：{+item.score} 分</span>
                    <span className="list-title-score">我的得分：
                      <span style={{fontSize:20}}>{+item.my_score}</span> 分
                    </span>
                  </p>}
                  description={
                    <>
                      <Checkbox.Group disabled defaultValue={item.my_answer?item.my_answer.split(","):null}>
                        {item.options.map((val,indexs)=>{
                          return (
                            <section key={val.id} className="list-info">
                              <label className="list-label">{this.optionsLabel[indexs]}</label>
                              <Checkbox className="list-radio"  value={val.id}>
                                <span className="list-radio-value" dangerouslySetInnerHTML={{__html: val.name}} />
                              </Checkbox>
                            </section>
                          )
                        })}
                      </Checkbox.Group>
                      <section className="answer">
                        <p className="exactness">
                          <span className="label">正确答案：</span>
                          <span className="info">{
                            item.options.map((val,index)=>{
                              if(item.answer.split(",").includes(val.id)){
                                return this.optionsLabel[index]
                              }
                              return false;
                            })
                          }</span>
                        </p>
                        <p className="analysis">
                          <span className="label">答案解析：</span>
                          <span className="info" dangerouslySetInnerHTML={{__html:item.analyze}} />
                        </p>
                      </section>
                    </>
                  }
                />
              </List.Item>
            }}
          />
          <List
            header={<div className="list-header">三、判断题（共{judgeList.length}小题；满分{judgeCountScore}分）</div>}
            loading={loading}
            className="exam-list"
            itemLayout="horizontal"
            dataSource={judgeList}
            renderItem={(item,index) => {
              return <List.Item>
                <List.Item.Meta
                  title={<p className="list-title">
                    <label className="list-title-label">[{index+1}]</label>
                    <span className="list-title-name" dangerouslySetInnerHTML={{__html: item.name}} />
                    <span className="list-title-score list-title-score-1">本题分值：{+item.score} 分</span>
                    <span className="list-title-score">我的得分：
                      <span style={{fontSize:20}}>{+item.my_score}</span> 分
                    </span>
                  </p>}
                  description={
                    <>
                      <Radio.Group disabled defaultValue={+item.my_answer}>
                        <Radio className="list-judge-radio" value={1}>
                          对
                        </Radio>
                        <Radio className="list-judge-radio" value={0}>
                          错
                        </Radio>
                      </Radio.Group>
                      <section className="answer">
                        <p className="exactness">
                          <span className="label">正确答案：</span>
                          <span className="info">{+item.answer?"对":"错"}</span>
                        </p>
                        <p className="analysis">
                          <span className="label">答案解析：</span>
                          <span className="info" dangerouslySetInnerHTML={{__html:item.analyze}} />
                        </p>
                      </section>
                    </>
                  }
                />
              </List.Item>
            }}
          />
        </section>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    examActions: bindActionCreators(examActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AchievementQuery);